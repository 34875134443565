import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useViewportScroll, useTransform, useSpring } from 'framer-motion';
import TypewriterEffect from '../../components/TypewriterEffect';

const Home = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [flickerStates, setFlickerStates] = useState(Array(8).fill(1));
  const [backgroundBrightness, setBackgroundBrightness] = useState(100);
  const audioContextRef = useRef(null);
  const activeAudiosRef = useRef([]);
  const [suffix, setSuffix] = useState(".de");
  const [showSuffix, setShowSuffix] = useState(true);
  const [giftCardCode, setGiftCardCode] = useState("X***-*TC**-*****");
  const [currentSection, setCurrentSection] = useState(0);
  const [canScroll, setCanScroll] = useState(true);
  const [showTypewriter, setShowTypewriter] = useState(false);
  const [showHome, setShowHome] = useState(false);

  const { scrollYProgress } = useViewportScroll();
  const smoothProgress = useSpring(scrollYProgress, { stiffness: 100, damping: 30, restDelta: 0.001 });

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    const flickerInterval = setInterval(() => {
      setFlickerStates(prev => prev.map((state, index) =>
        index === 0 || index === 1 ? Math.random() > 0.5 ? 0 : 1 : state
      ));
    }, 30);

    const backgroundFlickerInterval = setInterval(() => {
      setBackgroundBrightness(Math.random() * 40 + 50);
    }, 80);

    return () => {
      clearInterval(flickerInterval);
      clearInterval(backgroundFlickerInterval);
    };
  }, []);

  const handleStartExperience = () => {
    if (audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume();
    }
    setShowTypewriter(true);
    playClickSound();
  };

  const handleTypewriterComplete = () => {
    setShowTypewriter(false);
    setShowHome(true);
  };

  const playBackgroundSound = () => {
    if (audioContextRef.current) {
      if (audioContextRef.current.state === 'suspended') {
        audioContextRef.current.resume();
      }

      const audio = new Audio('/background.mp3');
      audio.loop = true;
      const source = audioContextRef.current.createMediaElementSource(audio);
      const gainNode = audioContextRef.current.createGain();

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      gainNode.gain.setValueAtTime(0.05, audioContextRef.current.currentTime);
      gainNode.gain.linearRampToValueAtTime(0.5, audioContextRef.current.currentTime + 5);

      audio.play().catch(() => { });

      const audioObj = { audio, source, gainNode };
      activeAudiosRef.current.push(audioObj);

      audio.addEventListener('ended', () => {
        activeAudiosRef.current = activeAudiosRef.current.filter(a => a !== audioObj);
        source.disconnect();
        gainNode.disconnect();
      });
    }
  };

  const playHoverSound = () => {
    if (audioContextRef.current) {
      if (audioContextRef.current.state === 'suspended') {
        audioContextRef.current.resume();
      }

      const audio = new Audio('/hover.mp3');
      const source = audioContextRef.current.createMediaElementSource(audio);
      const gainNode = audioContextRef.current.createGain();

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      gainNode.gain.setValueAtTime(1.2, audioContextRef.current.currentTime);

      audio.play().catch(() => {});

      const audioObj = { audio, source, gainNode };
      activeAudiosRef.current.push(audioObj);

      audio.addEventListener('ended', () => {
        activeAudiosRef.current = activeAudiosRef.current.filter(a => a !== audioObj);
        source.disconnect();
        gainNode.disconnect();
      });
    }
  };

  const playScrollSound = () => {
    if (audioContextRef.current) {
      if (audioContextRef.current.state === 'suspended') {
        audioContextRef.current.resume();
      }

      const audio = new Audio('/scrollLong.mp3');
      const source = audioContextRef.current.createMediaElementSource(audio);
      const gainNode = audioContextRef.current.createGain();

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      audio.play().catch(() => {});

      const audioObj = { audio, source, gainNode };
      activeAudiosRef.current.push(audioObj);

      audio.addEventListener('ended', () => {
        activeAudiosRef.current = activeAudiosRef.current.filter(a => a !== audioObj);
        source.disconnect();
        gainNode.disconnect();
      });
    }
  };

  const playClickSound = () => {
    if (audioContextRef.current) {
      if (audioContextRef.current.state === 'suspended') {
        audioContextRef.current.resume();
      }

      const audio = new Audio('/click.mp3');
      const source = audioContextRef.current.createMediaElementSource(audio);
      const gainNode = audioContextRef.current.createGain();
      const reverbNode = audioContextRef.current.createConvolver();

      const sampleRate = audioContextRef.current.sampleRate;
      const length = sampleRate * 2;
      const impulse = audioContextRef.current.createBuffer(2, length, sampleRate);
      const left = impulse.getChannelData(0);
      const right = impulse.getChannelData(1);

      for (let i = 0; i < length; i++) {
        const t = i / length;
        const amplitude = Math.exp(-8 * t);
        left[i] = (Math.random() * 2 - 1) * amplitude;
        right[i] = (Math.random() * 2 - 1) * amplitude;
      }

      reverbNode.buffer = impulse;

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
      gainNode.connect(reverbNode);
      reverbNode.connect(audioContextRef.current.destination);

      gainNode.gain.setValueAtTime(1.2, audioContextRef.current.currentTime);

      audio.play().catch(() => { });

      const audioObj = { audio, source, gainNode, reverbNode };
      activeAudiosRef.current.push(audioObj);

      audio.addEventListener('ended', () => {
        activeAudiosRef.current = activeAudiosRef.current.filter(a => a !== audioObj);
        source.disconnect();
        gainNode.disconnect();
        reverbNode.disconnect();
      });
    }
  };

  const imageScale = useTransform(smoothProgress, [0, 0.5], [0.5, 1]);
  const textOpacity = useTransform(smoothProgress, [0, 0.5], [0, 1]);

  return (
    <div style={{
      height: '100vh',
      overflow: 'hidden',
      background: `radial-gradient(circle, #111 0%, #000 100%)`,
      filter: `brightness(${backgroundBrightness}%)`,
      transition: 'filter 0.08s',
      userSelect: 'none',
      position: 'relative'
    }}>
      <AnimatePresence>
        {!isLoaded && !showTypewriter && !showHome && (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Poppins, sans-serif',
              color: 'white',
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                transform: 'scale(1.2)',
              }}
            >
              <motion.h1
                initial={{ y: -30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -50, opacity: 0 }}
                transition={{ duration: 0.5 }}
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  letterSpacing: '6px',
                  marginBottom: '2px',
                  background: 'linear-gradient(to right, #4169E1, #191970)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                jisllweb
              </motion.h1>
              <motion.p
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -50, opacity: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                style={{
                  fontSize: '7px',
                  fontWeight: '600',
                  letterSpacing: '2px',
                  marginBottom: '20px',
                  color: '#808080',
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                WEB DEVELOPER AT ITS FINEST
              </motion.p>
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '-10px' }}
              >
                <motion.button
                  onClick={handleStartExperience}
                  onMouseEnter={(e) => {
                    e.target.style.color = 'white';
                    playHoverSound();
                    e.target.nextSibling.style.backgroundColor = '#808080';
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = '#808080';
                    e.target.nextSibling.style.backgroundColor = 'white';
                  }}
                  style={{
                    background: 'none',
                    border: 'none',
                    color: '#808080',
                    fontSize: '8px',
                    fontWeight: '600',
                    cursor: 'pointer',
                    letterSpacing: '2px',
                    padding: '8px 0',
                    marginBottom: '5px',
                    transition: 'color 0.3s ease',
                    fontFamily: 'Poppins, sans-serif',
                  }}
                >
                  START EXPERIENCE
                </motion.button>
                <motion.div
                  style={{
                    width: '160px',
                    height: '2px',
                    backgroundColor: 'white',
                    transition: 'background-color 0.3s ease',
                  }}
                  exit={{
                    width: 0,
                    transition: { duration: 0.5, ease: "easeInOut" }
                  }}
                />
                {audioContextRef.current && audioContextRef.current.state === 'suspended' ? (
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                    style={{
                      fontSize: '7px',
                      fontWeight: '600',
                      color: '#FF4500',
                      fontFamily: 'Poppins, sans-serif',
                      marginTop: '10px'
                    }}
                  >
                    Please enable audio for the best experience.
                  </motion.p>
                ) : (
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                    style={{
                      fontSize: '7px',
                      fontWeight: '600',
                      color: '#808080',
                      fontFamily: 'Poppins, sans-serif',
                      marginTop: '10px'
                    }}
                  >
                    version - v.0.1.6
                  </motion.p>
                )}
              </motion.div>
            </motion.div>
          </motion.div>
        )}

        {showTypewriter && (
          <TypewriterEffect
            text={`Welcome to the future of web development.

Experience cutting-edge React, Framer Motion, and Web Audio API.

Immerse yourself in a world where design meets functionality.

Are you ready to explore the possibilities?`}
            onComplete={handleTypewriterComplete}
            playBackgroundSound={playBackgroundSound}
            playHoverSound={playHoverSound}
            playScrollSound={playScrollSound}
          />
        )}

        {showHome && (
          <motion.div
            key="home"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              overflow: 'hidden',
              userSelect: 'none',
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              style={{
                position: 'absolute',
                top: '20px',
                left: '40px',
                color: '#4169E1',
                fontSize: '12px',
                fontWeight: '600',
                letterSpacing: '6px',
                background: 'linear-gradient(to right, #4169E1, #191970)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                zIndex: 11,
              }}
            >
              Vaclav Web Development
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              style={{
                position: 'absolute',
                top: '20px',
                right: '40px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                zIndex: 11,
              }}
            >
              <motion.div
                style={{
                  fontSize: '12px',
                  fontWeight: '600',
                  color: '#808080',
                  marginBottom: '5px',
                }}
              >
                2000 USD Amazon Gift Card
              </motion.div>
              <motion.div
                style={{
                  fontSize: '12px',
                  fontWeight: '600',
                  background: 'linear-gradient(to right, #8B4513, #FFA500)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  marginBottom: '5px',
                }}
              >
                {giftCardCode}
              </motion.div>
              <motion.div
                style={{
                  fontSize: '10px',
                  color: '#808080',
                  textAlign: 'right',
                }}
              >
                Up to 3 letters revealed daily!
              </motion.div>
            </motion.div>

            <motion.div
              style={{ position: 'relative', display: 'flex', alignItems: 'center', zIndex: 10 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <AnimatePresence>
                {"jisllweb".split('').map((letter, index) => (
                  <motion.span
                    key={index}
                    style={{
                      fontSize: '6vw',
                      fontWeight: '300',
                      color: index <= 1 ? (flickerStates[index] ? '#FFFFFF' : '#808080') : '#808080',
                      textShadow: '0 0 15px rgba(128,128,128,0.5)',
                      position: 'relative',
                    }}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.5,
                      delay: index * 0.1 + 0.5
                    }}
                  >
                    {letter}
                  </motion.span>
                ))}
              </AnimatePresence>
              <AnimatePresence>
                {showSuffix && (
                  <motion.span
                    key="suffix"
                    style={{
                      fontSize: '3vw',
                      fontWeight: '300',
                      color: '#4169E1',
                      textShadow: '0 0 15px rgba(65,105,225,0.5)',
                      position: 'absolute',
                      bottom: '28px',
                      left: 'calc(100% + 5px)',
                    }}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{
                      opacity: 1,
                      y: 0
                    }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{
                      duration: 0.05,
                      delay: 0.8
                    }}
                  >
                    {suffix}
                  </motion.span>
                )}
              </AnimatePresence>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
              style={{
                position: 'absolute',
                bottom: '100px',
                color: '#808080',
                fontSize: '16px',
                fontWeight: '500',
                cursor: 'pointer',
                zIndex: 11,
              }}
              onMouseEnter={(e) => {
                e.target.style.color = 'white';
                playHoverSound();
              }}
              onMouseLeave={(e) => {
                e.target.style.color = '#808080';
              }}
            >
              Site is in built
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1 }}
              style={{
                position: 'absolute',
                bottom: '80px',
                color: '#808080',
                fontSize: '12px',
                fontWeight: '400',
                textAlign: 'center',
                zIndex: 11,
              }}
            >
              When "jisllweb" is fully white, the website is complete. But what comes next is a secret.
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1.5 }}
              style={{
                position: 'absolute',
                bottom: '40px',
                left: '40px',
                color: '#808080',
                fontSize: '12px',
                fontWeight: '400',
                zIndex: 11,
              }}
            >
              Copyright 2024 Vaclav (jisll)
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 2 }}
              style={{
                position: 'absolute',
                bottom: '40px',
                right: '40px',
                color: '#808080',
                fontSize: '12px',
                fontWeight: '400',
                display: 'flex',
                gap: '20px',
                zIndex: 11,
              }}
            >
              <motion.a
                href="https://instagram.com/maurice.cze"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ color: '#fff' }}
                onMouseEnter={playHoverSound}
                onClick={playClickSound}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                Instagram
              </motion.a>
              <motion.a
                href="https://github.com/jisll"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ color: '#fff' }}
                onMouseEnter={playHoverSound}
                onClick={playClickSound}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                Github
              </motion.a>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Home;