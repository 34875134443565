import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const TypewriterEffect = ({ text, onComplete, playBackgroundSound, playHoverSound, playScrollSound }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [charIndex, setCharIndex] = useState(-1);
  const [showUnderstand, setShowUnderstand] = useState(false);
  const [lines, setLines] = useState(['']);
  const [isExiting, setIsExiting] = useState(false);
  const audioContextRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      setCharIndex(0);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const playTypingSound = () => {
      if (!audioContextRef.current) return;

      const context = audioContextRef.current;
      const oscillator = context.createOscillator();
      const gainNode = context.createGain();

      oscillator.type = 'sine';
      oscillator.frequency.setValueAtTime(440 + Math.random() * 220, context.currentTime);
      gainNode.gain.setValueAtTime(0.25, context.currentTime);
      gainNode.gain.exponentialRampToValueAtTime(0.001, context.currentTime + 0.05);

      oscillator.connect(gainNode);
      gainNode.connect(context.destination);

      oscillator.start();
      oscillator.stop(context.currentTime + 0.05);
    };

    if (charIndex >= 0 && charIndex < text.length && audioContextRef.current) {
      const char = text[charIndex];
      const pauseDuration = char === '.' ? 500 : 50;

      timeoutRef.current = setTimeout(() => {
        setDisplayedText((prev) => prev + char);
        setCharIndex((prev) => prev + 1);
        playTypingSound();

        if (char === '.') {
          setLines((prevLines) => [...prevLines, '']);
        } else {
          setLines((prevLines) => {
            const newLines = [...prevLines];
            newLines[newLines.length - 1] += char;
            return newLines;
          });
        }
      }, pauseDuration);
    } else if (charIndex === text.length) {
      setTimeout(() => {
        setShowUnderstand(true);
      }, 500);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [charIndex, text]);

  const handleReadyClick = () => {
    setIsExiting(true);
    if (playScrollSound) playScrollSound();
    setTimeout(() => {
      if (playBackgroundSound) playBackgroundSound();
    }, 2000);
    setTimeout(() => {
      onComplete();
    }, 1000);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        color: 'white',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '18px',
        textAlign: 'center',
        padding: '0 20px',
        maxWidth: '800px',
        margin: '0 auto',
        lineHeight: '1.6',
      }}
    >
      <motion.div 
        style={{ marginBottom: '40px' }}
        animate={isExiting ? { opacity: 0, y: -50 } : { opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {lines.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </motion.div>
      {showUnderstand && (
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '-10px' }}
        >
          <motion.button
            onClick={handleReadyClick}
            onMouseEnter={(e) => {
              e.target.style.color = 'white';
              e.target.nextSibling.style.backgroundColor = '#808080';
              if (playHoverSound) playHoverSound();
            }}
            onMouseLeave={(e) => {
              e.target.style.color = '#808080';
              e.target.nextSibling.style.backgroundColor = 'white';
            }}
            style={{
              background: 'none',
              border: 'none',
              color: '#808080',
              fontSize: '8px',
              fontWeight: '600',
              cursor: 'pointer',
              letterSpacing: '2px',
              padding: '8px 0',
              marginBottom: '5px',
              transition: 'color 0.3s ease',
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            YES, I'M READY
          </motion.button>
          <motion.div
            style={{
              width: '160px',
              height: '2px',
              backgroundColor: 'white',
              transition: 'background-color 0.3s ease',
            }}
            exit={{
              width: 0,
              transition: { duration: 0.5, ease: "easeInOut" }
            }}
          />
        </motion.div>
      )}
    </motion.div>
  );
};

export default TypewriterEffect;